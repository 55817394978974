<template lang="pug">
.subscription-checkout-form

  .grid

    .select-plan
      h2 Escolha seu plano
      p.no-plan-selected-warning(v-if="showValidationErrors && !data.plan")
        | Selecione o seu plano para prosseguir

      .plans
        .plan(
          style="background-color: #7b61ff",
          :class="{ selected: data.plan =='annually' }",
          @click="selectPlan('annually')"
        )
          .title Anual
          .price
            | 12x
            br
            span.value R$&nbsp;149,90/mês
            br
            |+&nbsp;frete

        .plan(
          style="background-color: #65c9e0",
          :class="{ selected: data.plan =='biannually' }",
          @click="selectPlan('biannually')"
        )
          .title Semestral
          .price
            | 6x
            br
            span.value R$&nbsp;159,90/mês
            br
            |+&nbsp;frete

        .plan(
          style="background-color: #ef368b",
          :class="{ selected: data.plan =='monthly' }",
          @click="selectPlan('monthly')"
        )
          .title Mensal
          .price
            span.value R$&nbsp;169,90/mês
            br
            |+&nbsp;frete

      .recurrence-notice Todos os planos são renovados automaticamente

    .payment-method
      h2 Dados de pagamento
      .payment-information(v-if="showPIXPaymentMethod")
        RadioInput(
          name="payment-type-credit-card",
          label="Cartão de Crédito",
          value="CREDIT_CARD",
          v-model="paymentType")

        .credit-card-form(:class="{ collapsed: paymentType != 'CREDIT_CARD' }")
          CreditCard(ref="creditCard", v-model="creditCardInformation", :showInstallmentsOption="false")

        RadioInput(
          name="payment-type-pix",
          label="PIX",
          value="PIX",
          v-model="paymentType")

      .payment-information(v-if="!showPIXPaymentMethod")
        h3 Cartão de crédito
        .credit-card-form(:class="{ collapsed: paymentType != 'CREDIT_CARD' }")
          CreditCard(ref="creditCard", v-model="creditCardInformation", :showInstallmentsOption="false")

    .summary
      h2 Resumo da assinatura
      .checkout-summary
        table
          tbody
            tr
              td.left Box Magenta
              td.right(v-if="monthlyPrice != undefined")
                | R$&nbsp;{{ centsToString(monthlyPrice) }}
              td.right(v-else) R$&nbsp;--,--

            tr.subscription-gift(v-if="planGift != null")
              td.left.magenta {{ planGift.giftDescription }}
              td.right.bold.magenta Grátis!

            tr.subscription-gift(v-if="planGift != null && showNaturaPromotion")
              td.left.magenta Batom Natura
              td.right.bold.magenta Grátis!

            tr.subscription-gift(v-else-if="showNaturaPromotion")
              td.left.magenta Batom Natura
              td.right.bold.magenta Grátis!

            tr(v-if="couponValue != undefined")
              td.left.magenta Cupom {{ discountCoupon?.id?.toUpperCase() }}
              td.right.bold.magenta
                | -R$&nbsp;{{ centsToString(couponValue) }}

            tr.subscription-gift(v-if="giftCoupon != undefined")
              td.left.magenta {{ giftCoupon?.giftDescription }}
              td.right.bold.magenta Grátis!

        hr

        table
          tbody
            tr
              td.left Frete
              td.right(v-if="shippingRate != undefined")
                span(v-if="shippingRate > 0") R$&nbsp;{{ centsToString(shippingRate) }}
                span.bold.magenta(v-if="shippingRate == 0") GRÁTIS
              td.right(v-else-if="loadingShipping")
                Loading.loading
              td.right(v-else) R$&nbsp;--,--

            tr
              td.left Est. de entrega
              td.right(v-if="shippingDays != undefined")
                | {{ shippingDays }} dias úteis
              td.right(v-else-if="loadingShipping")
                Loading.loading
              td.right(v-else) --

        hr

        table(v-if="couponValue == undefined")
          tbody
            tr
              td.left Total
              td.right(v-if="total != undefined")
                span(v-if="installments && installments > 1") {{ installments }}x&#32;
                | R$&nbsp;{{ centsToString(total) }}
              td.right(v-else-if="loadingShipping")
                Loading.loading
              td.right(v-else) R$&nbsp;--,--

        table(v-else)
          tbody
            tr
              td.left
                span(v-if="discountParts && discountParts > 1") Primeiros {{ discountParts }} meses
                span(v-else) Primeiro mês

              td.right(v-if="total != undefined")
                span(v-if="discountParts && discountParts > 1") {{ discountParts }}x&#32;
                | R$&nbsp;{{ centsToString(total - (couponValue / (discountParts ?? 1.0) )) }}

              td.right(v-else-if="loadingShipping")
                Loading.loading

              td.right(v-else) R$&nbsp;--,--
            tr
              td.left Próximos meses
              td.right(v-if="total != undefined")
                | R$&nbsp;{{ centsToString(total) }}
              td.right(v-else-if="loadingShipping")
                Loading.loading
              td.right(v-else) R$&nbsp;--,--

        .recurrence-notice * Todos os planos são renovados automaticamente

        .coupon
          form(@submit.prevent="applyCoupon(couponCode)")
            TextInput(
              label="Cupom",
              v-model.trim="couponCode"
            )

            button(v-show="false", type="submit")

            Button(:disabled="couponCode == ''" :loading="loadingCoupon", label="Aplicar", @click="applyCoupon(couponCode)", rounded)

  .notice
    | Ao assinar, você concorda com a nossa&#32;
    NuxtLink(:to="{ name: 'privacy-policy' }", target="_blank") política de privacidade&#32;
    | e nosso&#32;
    NuxtLink(:to="{ name: 'subscription-contract' }", target="_blank") contrato de assinatura

</template>
<script setup lang="ts">
import type { PropType, WatchStopHandle } from 'vue'

import type { AddressInfo } from '~/components/address/AddressForm.vue'
import type { CreditCardInformation } from '~/components/input/CreditCard.vue'
import type { Coupon } from '~/services/subscription/coupon/couponService'
import type { SubscriptionGiftCouponInfo } from '~/services/pancake/pancakeService'
import type { SubscriptionGiftAvailabilityResponse } from '~/services/subscription/promotion/promotionService'

import CreditCard from '~/components/input/CreditCard.vue'

import { planAliasToId, planBasePrice, planInstallments, planDiscountParts } from '~/services/subscription/subscriptionService'

import CouponService from '~/services/subscription/coupon/couponService'
import ShippingAddressService from '~/services/shippingAddress/shippingAddressService'
import { default as PromotionService, SubscriptionGiftAvailability } from '~/services/subscription/promotion/promotionService'
import { useAuth } from '~/store/auth'
import { FetchError } from 'ofetch'
import pancakeService from '~/services/pancake/pancakeService'

export interface SubscriptionCheckoutFormModel {
  plan: string | null,
  creditCardInformation: CreditCardInformation,
  discountCouponId: string | null,
  giftCoupon: {
    couponId: string,
    couponCode: string,
  } | null
  promotions: string[],
  subscriptionGiftId: string | null
}

const supportedPlans = [
  "monthly",
  "quarterly",
  "biannually",
  "annually",
]

const props = defineProps({
  customerCPF: {
    type: String,
  },
  shippingAddressInfo: {
    type: Object as PropType<AddressInfo>,
    required: true,
  },
  data: {
    type: Object as PropType<SubscriptionCheckoutFormModel>,
    required: true,
  },
})

const auth = useAuth()
const experiments = useExperiments()
const notifications = useNotifications()

const showNaturaPromotion = naturaPromotionActive

const selectedPlan = ref('monthly')
const showPIXPaymentMethod = ref(false)
const paymentType = ref("CREDIT_CARD")
const creditCard = ref<InstanceType<typeof CreditCard>>()
const creditCardInformation = ref<CreditCardInformation>(props.data.creditCardInformation)
const shippingCost = ref<number | null>(null)
const shippingDays = ref<number | null>(null)
const shipppingPostalCode = ref<string | null>(null)

const couponCode = ref<string>("")
const pendingCouponCodes = ref<string[]>([])
const discountCoupon = ref<Coupon | null>(null)
const discountCouponId = ref<string | null>(null)
const giftCoupon = ref<SubscriptionGiftCouponInfo | null>(null)
const giftCouponId = ref<string | null>(null)

const availableSubscriptionGiftPromotions = ref<SubscriptionGiftAvailabilityResponse[]>([])
const freeShippingSP = ref(false)

const loadingShipping = ref(false)
const loadingCoupon = ref(false)

const showValidationErrors = ref(false)

const planId = computed(() => {
  if (props.data.plan) {
    return planAliasToId(props.data.plan, discountCoupon.value != null)
  } else {
    return undefined
  }
})

const monthlyPrice = computed(() => {
  if (planId.value !== undefined) {
    return planBasePrice[planId.value] ?? undefined
  } else {
    return undefined
  }
})

const installments = computed(() => {
  if (planId.value !== undefined) {
    return planInstallments[planId.value] ?? undefined
  } else {
    return undefined
  }
})

const discountParts = computed(() => {
  if (planId.value !== undefined) {
    return planDiscountParts[planId.value] ?? undefined
  } else {
    return undefined
  }
})

const couponValue = computed(() => {
  if (discountCoupon.value) {
    if (discountCoupon.value.amount) {
      return discountCoupon.value.amount
    } else if (discountCoupon.value.percentage && monthlyPrice.value) {
      return discountCoupon.value.percentage * monthlyPrice.value
    } else {
      return undefined
    }
  } else {
    return undefined
  }
})

const shippingRate = computed(() => {
  if (freeShippingSP.value) {
    return 0
  } else {
    return shippingCost.value
  }
})

const total = computed(() => {
  if (monthlyPrice.value == null || shippingRate.value == null) { return undefined }

  return monthlyPrice.value + shippingRate.value
})

const planGift = computed(() => {
  if (!planId.value) {
    return null
  } else {
    return availableSubscriptionGiftPromotions.value.find((promotion) => promotion.planIds.indexOf(planId.value!) >= 0) ?? null
  }
})

const selectPlan = async (plan: string) => {
  props.data.plan = plan

  if (pendingCouponCodes.value.length > 0) {
    for (const coupon of pendingCouponCodes.value) {
      await applyCoupon(coupon)
    }

    pendingCouponCodes.value = []
  }
}

const calculateShippingCost = async (shippingAddressInfo: AddressInfo) => {
  if (shipppingPostalCode.value == shippingAddressInfo.postalCode) return

  loadingShipping.value = true

  try {
    const response = await ShippingAddressService.calculateShippingRate({
      customerEmail: auth.userEmail ?? "",
      state: shippingAddressInfo.state,
      postalCode: shippingAddressInfo.postalCode
    })

    shippingCost.value = response.price
    shippingDays.value = response.days

    shipppingPostalCode.value = shippingAddressInfo.postalCode

    gtmPush({
      event: 'shipping-calculated',
      state: shippingAddressInfo.state,
      postalCode: shippingAddressInfo.postalCode,
      shippingPrice: response.price,
      shippingDays: response.days,
    })
  } catch (e) {
    gtmPush({
      event: 'shipping-calculation-failed',
      state: shippingAddressInfo.state,
      postalCode: shippingAddressInfo.postalCode,
    })

    captureException(new Error('Failed to calculate shipping cost', { cause: e }))

    notifications.error("Não foi possível calcular o valor do frete. Por favor, tente novamente mais tarde")
  } finally {
    loadingShipping.value = false
  }
}

const applyCoupon = async (couponText: string, showNotifications: boolean = true) => {
  const coupon = couponText.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()

  if (!coupon) return

  if (props.customerCPF) {
    loadingCoupon.value = true

    try {
      if (planId.value == null) {
        if (pendingCouponCodes.value.indexOf(coupon) > -1) {
          pendingCouponCodes.value.splice(pendingCouponCodes.value.indexOf(coupon), 1)
        }

        pendingCouponCodes.value.push(coupon)

        if (showNotifications) {
          notifications.error("Selecione um plano para aplicar o cupom")
        }

        return
      }

      const request = {
        couponCode: coupon,
        customerCPF: props.customerCPF,
        planId: planId.value ?? null,
      }

      const response = await pancakeService.validateSubscriptionCoupon(request)

      if (response.couponExhausted) {
        if (showNotifications) {
          notifications.error(`Cupom ${coupon.toUpperCase()} esgotado`)
        }

        gtmPush({
          event: 'coupon-failed',
          couponCode: coupon,
          reason: 'exhausted',
        })
      } else if (response.couponAlreadyReceived) {
        if (showNotifications) {
          notifications.error(`Cupom ${coupon.toUpperCase()} válido apenas para novos assinantes`)
        }

        gtmPush({
          event: 'coupon-failed',
          couponCode: coupon,
          reason: 'already-received',
        })
      } else if (response.discountCoupon == null && response.giftCoupon == null) {
        if (showNotifications) {
          notifications.error(`Cupom ${coupon.toUpperCase()} não disponível`)
        }

        gtmPush({
          event: 'coupon-failed',
          couponCode: coupon,
          reason: 'not-available',
        })
      } else {
        if (showNotifications) {
          notifications.success(`Cupom ${coupon.toUpperCase()} aplicado`)
        }

        if (response.discountCoupon) {
          discountCoupon.value = response.discountCoupon
          discountCouponId.value = response.discountCoupon?.id ?? null
          props.data.discountCouponId = discountCouponId.value

          gtmPush({
            event: 'coupon-applied',
            couponId: response.discountCoupon?.id,
            couponCode: coupon,
            couponType: 'discount',
          })
        }

        if (response.giftCoupon) {
          giftCoupon.value = response.giftCoupon
          giftCouponId.value = response.giftCoupon?.couponId ?? null
          props.data.giftCoupon = response.giftCoupon ? { couponId: response.giftCoupon.couponId, couponCode: response.giftCoupon.couponCode } : null

          gtmPush({
            event: 'coupon-applied',
            couponId: response.giftCoupon?.couponId,
            couponCode: coupon,
            couponType: 'gift',
          })
        }

        couponCode.value = ""
      }

      return response
    } catch (e) {
      if (showNotifications) {
        notifications.error("Não foi possível aplicar este cupom. Por favor, tente novamente mais tarde")
      }

      captureException(new Error('Failed to apply coupon', { cause: e }))

      gtmPush({
        event: 'coupon-failed',
        couponCode: coupon,
        reason: 'unknown',
      })
    } finally {
      loadingCoupon.value = false
    }
  }
}

const reloadGiftCoupon = async () => {
  if (!props.customerCPF || !giftCoupon.value) {
    return
  }

  const couponCode = giftCoupon.value.couponCode
  giftCoupon.value = null
  giftCouponId.value = null
  props.data.giftCoupon = null

  try {
    loadingCoupon.value = true

    const request = {
      couponCode: couponCode,
      customerCPF: props.customerCPF,
      planId: planId.value ?? null,
    }

    const response = await pancakeService.validateSubscriptionCoupon(request)

    giftCoupon.value = response.giftCoupon
    giftCouponId.value = response.giftCoupon?.couponId ?? null
    props.data.giftCoupon = response.giftCoupon ? { couponId: response.giftCoupon.couponId, couponCode: response.giftCoupon.couponCode } : null
  } catch (e) {
    captureException(new Error('Failed to reload coupons', { cause: e }))
  } finally {
    loadingCoupon.value = false
  }
}

watch(() => props.data.plan, async (value, oldValue) => {
  if (value && value != oldValue) {
    await reloadGiftCoupon()
  }
})

const validate = async () => {
  showValidationErrors.value = true

  return await creditCard.value?.validate() && props.data.plan
}

var watchExperimentsStopHandle: WatchStopHandle
var shippingAddressWatchStopHandle: WatchStopHandle
onMounted(() => {
  if (props.shippingAddressInfo.state && props.shippingAddressInfo.postalCode) {
    calculateShippingCost(props.shippingAddressInfo)
  }

  if (props.data.plan && supportedPlans.indexOf(props.data.plan) == -1) {
    props.data.plan = null
  }

  if (props.data.subscriptionGiftId) {
    PromotionService.getSubscriptionGiftAvailability(props.data.subscriptionGiftId).then((response) => {
      if (response.availability == SubscriptionGiftAvailability.OUT_OF_STOCK) {
        notifications.warning(`${response.giftDescription} esgotado`, 6000)
      } else if (response.availability == SubscriptionGiftAvailability.NOT_RUNNING) {
        notifications.warning(`${response.giftDescription} esgotado`, 6000)
      } else if (response.availability == SubscriptionGiftAvailability.INELIGIBLE_CUSTOMER) {
        notifications.warning(`Promoção não disponível para assinantes recentes`, 6000)
      }
    })
  }

  PromotionService.listAvaiableSubscriptionGiftPromotions().then((response) => {
    availableSubscriptionGiftPromotions.value = response
  })

  watchExperimentsStopHandle = watchEffect(() => {
    freeShippingSP.value = experiments.get("freeShippingSP") === 'true'

    if (freeShippingSP.value) {
      props.data.promotions = ["free-shipping-sp"]
    } else {
      props.data.promotions = []
    }
  })

  shippingAddressWatchStopHandle = watch(
    props.shippingAddressInfo,
    async (value, oldValue) => {
      if (value.postalCode && value.state && value.postalCode.length == 9) {
        await calculateShippingCost(value)
      }
    }
  )
})

onUnmounted(() => {
  watchExperimentsStopHandle?.apply(this)
  shippingAddressWatchStopHandle?.apply(this)
})

defineExpose({
  applyCoupon,
  calculateShippingCost,
  validate
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.subscription-checkout-form
  position: relative

  .notice
    margin: 16px 0
    font-size: 14px
    text-align: center

    a
      color: $magenta

  .grid
    display: grid
    grid-template-areas: "plans" "payment-method" "summary"
    grid-template-columns: 1fr
    gap: 32px

    @include breakpoint(laptop)
      grid-template-areas: "plans summary" "payment-method summary"
      grid-template-columns: 2fr 1fr

    .select-plan
      grid-area: plans

      .no-plan-selected-warning
        font-weight: bold
        color: $acquaBlue

      .plans
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: stretch
        gap: 16px

        @include breakpoint(tablet)
          flex-direction: row
          justify-content: space-evenly

        .plan
          position: relative
          display: grid
          grid-template-areas: "title price" "title phrase"
          gap: 8px
          cursor: pointer
          padding: 16px
          border-radius: 8px
          color: white
          text-align: center
          font-family: Spartan, sans-serif
          flex-grow: 0
          font-size: 14px

          &.selected::before
            content: ''
            position: absolute
            top: -5px
            left: -5px
            width: calc(100% + 4px)
            height: calc(100% + 4px)
            border-radius: 12px
            border: 3px solid #EE3D8A

          .title
            display: flex
            flex-direction: column
            justify-content: center
            grid-area: title
            font-weight: bold
            font-size: 18px

          .price
            grid-area: price

            span.value
              font-size: 18px
              font-weight: 800

          .phrase
            grid-area: phrase

          @include breakpoint(mobileonly)
            font-size: 16px

            .title
              font-size: 22px

          @include breakpoint(tablet)
            grid-template-areas: "title" "price" "phrase"
            grid-template-columns: 1fr

      .recurrence-notice
        margin-top: 16px
        text-align: center
        font-size: 14px
        font-weight: bold

    .payment-method
      grid-area: payment-method

      .credit-card-form
        display: inline-block
        overflow: hidden
        width: 100%
        max-width: 512px
        max-height: 380px
        transition: max-height .3s cubic-bezier(0.45, 0, 0.55, 1), padding-bottom .3s cubic-bezier(0.45, 0, 0.55, 1)
        text-align: center
        background-color: $beige
        padding: 16px
        border-radius: 8px

        &.collapsed
          max-height: 0
          padding-bottom: 0

    .summary
      grid-area: summary

      .checkout-summary
        margin-top: 32px
        max-width: 480px
        margin: 0 auto
        border-radius: 8px
        background-color: $beige
        padding: 16px

        hr
          border: none
          border-bottom: 2px solid white

        .bold
          font-weight: bold

        .magenta
          color: $magenta

        .recurrence-notice
          margin-top: 16px
          font-size: 12px

        .coupon
          margin-top: 32px

          .text-input
            input
              height: 10px
              border-color: black !important

        table
          width: 100%
          line-height: 1.2em
          border-collapse: collapse

          tr
            height: 28px

            &.subscription-gift
              white-space: pre-line

          .left
            font-weight: bold

          .right
            text-align: right

          .lineThrough
            text-decoration: line-through

          &.total
            margin-top: 8px
            padding-top: 8px
            border-top: 1px solid black
            font-weight: bold

          &.subscription-gift
            position: relative
            background-color: $acquaBlue
            color: white
            border-radius: 4px
            line-height: 16px
            margin-top: 16px
            white-space: pre-line

            &::before
              position: absolute
              display: block
              top: 2px
              left: 2px
              width: calc(100% - 4px)
              height: calc(100% - 4px)
              border: dashed 1px white
              border-radius: 4px
              content: ''
              box-sizing: border-box

            td
              padding: 12px

            .right
              font-size: 14px
</style>
<style lang="sass">
@import '~/assets/styles/variables'

.subscription-checkout-form
  .grid

    .payment-method

      .credit-card-form

        .text-input input, .select-input select
          border-color: white

        .text-input.error input, .select-input.error select
          border-color: $acquaBlue

        .text-input input:focus, .select-input select:focus
          border-color: $magenta


    .summary
      .checkout-summary
        .coupon
          position: relative

          .button
            position: absolute
            bottom: 16px
            right: 0
            margin: 4px 0
            padding: 2px 8px
            height: 32px
            font-size: 14px
            font-weight: 800

          .text-input
            input
              font-size: 14px
              height: 28px
              border-bottom: 1px solid black
              padding: 8px 2px

              &:focus
                border-color: $magenta
</style>
